import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

const AccordionItem = ({ data }) => {
    const [isExpanded, setToggleExpansion] = useState(false)
    const [setHeight, setHeightState] = useState('0px')

    const content = useRef(null)

    const toggleAccordionItem = () => {
        setToggleExpansion((prev) => !prev)
        setHeightState(
            isExpanded === true ? '0px' : `${content.current.scrollHeight}px`
        )
    }

    return (
        <div
            className={`c-accordion-item ${
                isExpanded ? 'is-open' : 'is-closed'
            }`}
        >
            <div
                role="button"
                tabIndex="0"
                className="c-accordion-item__title"
                onClick={toggleAccordionItem}
                onKeyDown={toggleAccordionItem}
            >
                <span>{data.title}</span>
            </div>
            <div
                className="c-accordion-item__content"
                ref={content}
                style={{ maxHeight: `${setHeight}` }}
            >
                <div
                    className="c-accordion-item__content-inner"
                    dangerouslySetInnerHTML={{ __html: data.content }}
                />
            </div>
        </div>
    )
}

AccordionItem.propTypes = {
    /**
     * Data
     */
    data: PropTypes.shape({
        title: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired
    }).isRequired
}

AccordionItem.defaultProps = {
    data: {
        title: 'Accordion item title',
        content: 'Accordion item content'
    }
}

export default AccordionItem
